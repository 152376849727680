import React, { useRef, useEffect, useState } from 'react';
import maplibregl from 'maplibre-gl';
import { gsap } from "gsap";
import { CSSPlugin } from 'gsap/CSSPlugin'
import './map.css';

const locations = [
    { center: [132.837444, -24.137734], zoom: 9 },
    { center: [138.224611, -27.301995], zoom: 8 },
    { center: [149.045032, -20.441705], zoom: 12 },
    { center: [128.349530, -15.501423], zoom: 12 },
    { center: [136.447791, -13.976373], zoom: 13 },
    { center: [148.0309017, -40.393909], zoom: 13 },
]


export default function Map() {
    const mapContainer1 = useRef(null);
    const mapContainer2 = useRef(null);
    const mapContainer3 = useRef(null);
    const mapContainer4 = useRef(null);
    const mapContainer5 = useRef(null);
    const mapContainer6 = useRef(null);

    const map = useRef(null);

    const map1 = useRef(null);
    const map2 = useRef(null);
    const map3 = useRef(null);
    const map4 = useRef(null);
    const map5 = useRef(null);
    const map6 = useRef(null);

    const [lng] = useState(151.214207);
    const [lat] = useState(-33.862081);
    const [zoom] = useState(14);
    const [API_KEY] = useState('7A00fW4rncGAg9jvyKML');

    gsap.registerPlugin(CSSPlugin)

    const setupAnimation = (refMap, ref) => {

        // gsap.to(mapContainer1.current, { duration:0, opacity: '0' });
        // map1.current.on('sourcedata', function(e) {
        //     if (e.isSourceLoaded) {
        //         gsap.to(mapContainer1.current, { duration:1, opacity: '1' });
        //         gsap.to(mapContainer1.current, { duration:10, transform: 'translateY(1000px)', ease: "linear" });

        //     }
        // });



        // gsap.to(ref.current, { duration:0, opacity: '0' });
        //gsap.to(ref.current, { duration:0, transform: 'translateY(1000px)' });
            
        refMap.current.on('sourcedata', function (e) {
            if (e.isSourceLoaded && e.sourceDataType !== "metadata") {
                gsap.to(ref.current, { duration:1, opacity: '1' });
                //gsap.to(ref.current, { duration:10, delay:8*Math.random(), transform: 'translateY(0px)', ease: "quad.inOut" });
            }
        });

        // refMap.current.on("click", ()=>{
        //     console.log("click")
        //             refMap.current.flyTo({
        //               duration: 10,
        //               center: [
        //                 lng, lat + 0.1
        //                 ],
        //               essential: true,
        //             });
        // })
    }


    useEffect(() => {
        if (map1.current) return; //stops map from intializing more than once
        if (map2.current) return; //stops map from intializing more than once
        if (map3.current) return; //stops map from intializing more than once
        if (map4.current) return; //stops map from intializing more than once
        if (map5.current) return; //stops map from intializing more than once
        if (map6.current) return; //stops map from intializing more than once


        const mapSettings = {
            zoom: zoom,
            maplibreLogo: false,
            center: [lng, lat],
            style: {
                'version': 8,
                'sources': {
                    'satellite': {
                        'type': 'raster',
                        'url':
                            `https://api.maptiler.com/tiles/satellite-v2/tiles.json?key=${API_KEY}`,
                        'tileSize': 256
                    },
                },
                'layers': [
                    {
                        'id': 'background',
                        'type': 'background',
                        'paint': {
                        'background-color': 'rgb(4,7,14)'
                        }
                        },
                    {
                        'id': 'satellite',
                        'type': 'raster',
                        'source': 'satellite'
                    },
                ]
            }
        }


        map1.current = new maplibregl.Map({
            container: mapContainer1.current,
            ...mapSettings,
            ...locations[0],
        });

        setupAnimation(map1, mapContainer1);

        map2.current = new maplibregl.Map({
            container: mapContainer2.current,
            ...mapSettings,
            ...locations[1],
        });

        setupAnimation(map2, mapContainer2)


        map3.current = new maplibregl.Map({
            container: mapContainer3.current,
            ...mapSettings,
            ...locations[2],
        });
        setupAnimation(map3, mapContainer3)


        map4.current = new maplibregl.Map({
            container: mapContainer4.current,
            ...mapSettings,
            ...locations[3],
        });
        setupAnimation(map4, mapContainer4)


        map5.current = new maplibregl.Map({
            container: mapContainer5.current,
            ...mapSettings,
            ...locations[4],
        });
         setupAnimation(map5, mapContainer5)


        map6.current = new maplibregl.Map({
            container: mapContainer6.current,
            ...mapSettings,
            ...locations[5],
        });
         setupAnimation(map6, mapContainer6)


    });


    return (
        <div ref={map} className="map-wrap">
            <div ref={mapContainer1} className="map" >
                <div className="map_outer" />
            </div>
            <div ref={mapContainer2} className="map" >
                <div className="map_outer" />
            </div>
            <div ref={mapContainer3} className="map" >
                <div className="map_outer" />
            </div>
            <div ref={mapContainer4} className="map" >
                <div className="map_outer" />
            </div>
            <div ref={mapContainer5} className="map" >
                <div className="map_outer" />
            </div>
            <div ref={mapContainer6} className="map" >
                <div className="map_outer" />
            </div>
        </div>
    );



}




/*

        map1.current = new maplibregl.Map({
            container: mapContainer1.current,
            style: `https://api.maptiler.com/maps/winter/style.json?key=${API_KEY}`,
            center: [lng, lat],
            zoom: zoom,
            maplibreLogo: false,
        });
        gsap.to(mapContainer1.current, { duration:0, opacity: '0' });
        map1.current.on('sourcedata', function(e) {
            if (e.isSourceLoaded) {
                console.log("loaded")
                gsap.to(mapContainer1.current, { duration:1, opacity: '1' });
                gsap.to(mapContainer1.current, { duration:10, transform: 'translateY(1000px)', ease: "linear" });

            }
            });



        map2.current = new maplibregl.Map({
            container: mapContainer2.current,
            zoom: zoom,
            center: [lng, lat],
            style: {
                'version': 8,
                'sources': {
                    'satellite': {
                        'type': 'raster',
                        'url':
                        `https://api.maptiler.com/tiles/hillshade/tiles.json?key=${API_KEY}`,
                        'tileSize': 256
                    },
                },
                'layers': [
                    {
                        'id': 'satellite',
                        'type': 'raster',
                        'source': 'satellite'
                    },
                ]
            }
        });


        map3.current = new maplibregl.Map({
            container: mapContainer3.current,
            zoom: zoom,
            center: [lng, lat],
            style: {
                'version': 8,
                'sources': {
                    'satellite': {
                        'type': 'raster',
                        'url':
                        `https://api.maptiler.com/tiles/terrain-rgb/tiles.json?key=${API_KEY}`,
                        'tileSize': 256
                    },
                },
                'layers': [
                    {
                        'id': 'satellite',
                        'type': 'raster',
                        'source': 'satellite'
                    },
                ]
            }
        });



        map4.current = new maplibregl.Map({
            container: mapContainer4.current,
            minZoom: zoom,
            zoom: zoom,
            center: [lng, lat],
            style: {
                'version': 8,
                'sources': {
                    'satellite': {
                        'type': 'raster',
                        'url':
                        `https://api.maptiler.com/tiles/satellite-v2/tiles.json?key=${API_KEY}`,
                        'tileSize': 256
                    },
                },
                'layers': [
                    {
                        'id': 'satellite',
                        'type': 'raster',
                        'source': 'satellite'
                    },
                ]
            }
        });



        map5.current = new maplibregl.Map({
            container: mapContainer5.current,
            minZoom: zoom,
            zoom: zoom,
            center: [lng, lat],
            style: {
                'version': 8,
                'sources': {
                    'satellite': {
                        'type': 'raster',
                        'url':
                            `https://api.maptiler.com/tiles/satellite/tiles.json?key=${API_KEY}`,
                        'tileSize': 256
                    },
                },
                'layers': [
                    {
                        'id': 'satellite',
                        'type': 'raster',
                        'source': 'satellite'
                    },
                ]
            }
        });




          map6.current = new maplibregl.Map({
              container: mapContainer6.current,
              style: `https://api.maptiler.com/maps/hybrid/style.json?key=${API_KEY}`,
              center: [lng, lat],
              zoom: zoom
            });



            */